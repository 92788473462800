
import { Inject, Watch, Component, Prop, Vue } from 'vue-property-decorator'
import { Tooltip } from 'element-ui'
import { SassBoolean } from 'sass'
import { FilesMixin } from '~/store/mixins'

@Component({
  mixins: [FilesMixin],
  components: {
    [Tooltip.name]: Tooltip
  }
})

export default class linkModal extends Vue {
  @Inject() readonly et!: any

  @Prop({
    type: String,
    default: ''
  })
  readonly icon!: string

  @Prop({
    type: String,
    default: ''
  })
  readonly svg!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isActive!: boolean

  @Prop({
    type: String,
    required: true
  })
  readonly tooltip!: string

  @Prop({
    type: Boolean,
    required: true
  })
  readonly enableTooltip!: boolean

  @Prop({
    type: Function
  })
  readonly command!: Function

  @Prop({
    type: Boolean,
    default: false
  })
  readonly customElement!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly readonly!: SassBoolean

  @Prop({
    type: Object,
    default: false
  })
  readonly editor!: {}

  @Prop({
    type: Object,
    default: false
  })
  readonly extension!: {}

  @Prop({
    type: Object,
    default: () => {}
  })
  readonly ctx!: {}

  private get commandButtonClass (): object {
    return {
      'el-tiptap-editor__command-button': true,
      'el-tiptap-editor__command-button--active': this.isActive,
      'el-tiptap-editor__command-button--readonly': this.readonly
    }
  }

  visible = false

  onClick () {
    if (!this.readonly) { if (this.customElement) { this.$emit('command') } else { this.visible = true } }
  }

  onClose () { this.visible = false }

  linkAttrs = {} as any

  @Watch('visible', { immediate: true })
  onDialogVisibleChange () {
    this.linkAttrs = { href: '', openInNewTab: true }
  }

  addLink () {
    (this.editor as any).commands.link(this.linkAttrs)
    this.onClose()
  }

  async uploadFileReq (params: { file: File }) {
    const { file } = params

    try {
      const data = await (this as any).uploadFile(
        { files: [file], target: 'files', type: file.type },
        { notifyMessage: true }
      )

      this.linkAttrs.href = this.$config.S3_DOMAIN + data?.[0]?.origin
      this.addLink()
    } catch (error) {}
  }
}

